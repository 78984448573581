import React from 'react';
import HeroOne from "../../hero/HeroOne";
import HeroTwo from "../../hero/HeroTwo";
import HomeServices from "../../home-services/HomeServices";
import Wave from "../../wave/Wave";

function Home(props) {
    return (
        <div>
            <HeroTwo/>
            <HomeServices/>
            {/*<Wave/>*/}
        </div>
    );
}

export default Home;