import React, {useState, useEffect} from 'react';
import './navbar.css';
import {Link} from "react-router-dom";
import {CgMenuRight, CgClose} from 'react-icons/cg';
import logoNew from "../../assets/images/logo-rebrand.png";

function Navbar() {

    const [clickStatus, setClickStatus] = useState(false);
    const [menuBtnStatus, setMenuBtnStatus] = useState(false);
    const [activeNav, setActiveNav] = useState('');

    const handleClickStatus = () => setClickStatus(!clickStatus);
    const closeMenu = () => setClickStatus(false);

    const showMenuBtn = () => {
        if(window.innerWidth <= 1024){
            setMenuBtnStatus(false);
        }else{
            setMenuBtnStatus(true);
        }
    }

    useEffect(() => {
        showMenuBtn();
    }, []);

    window.addEventListener('resize', showMenuBtn);

    return (
        <nav className="navbar">
            <img src={logoNew} className="logo_img"/>
            <ul className={clickStatus ? 'nav__links active' : 'nav__links'} >
                <Link to='/'  onClick={() => {

                    setActiveNav('')}}
                    className={activeNav === '' ? 'home nav__item active' : 'home nav__item' }>
                    <li>Home</li>
                </Link>
                <Link to='/about' onClick={() => {

                    setActiveNav('/about')}}
                      className={activeNav === '/about' ? 'about nav__item active' : 'about nav__item' }>
                    <li>About</li>
                </Link>
                <Link to='/services' onClick={() => {

                    setActiveNav('/services')}}
                      className={activeNav === '/services' ? 'services nav__item active' : 'services nav__item' }>
                    <li>Services</li>
                </Link>
                <Link to='/contact' onClick={() => {

                    setActiveNav('/contact')}}
                      className={activeNav === '/contact' ? 'contact nav__item active' : 'contact nav__item' }>
                    <li>Contact</li>
                </Link>
            </ul>

            <button className='mobile__menu-icon' onClick={handleClickStatus}>
                {clickStatus ? <CgClose/> : <CgMenuRight/> }
            </button>
        </nav>
    );
}

export default Navbar;