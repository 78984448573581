import logo from './logo.svg';
import './App.css';
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Home from "./components/pages/Home/Home";
import Contact from "./components/pages/Contact/Contact";
import Services from "./components/pages/Services/Services";
import About from './components/pages/About/About';
import Navbar from './components/nav/Navbar';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar/>
        <Routes>
          <Route path='/' exact element={<Home/>}/>
          <Route path='/about' exact element={<About/>}/>
          <Route path='/services' exact element={<Services/>}/>
          <Route path='/contact' exact element={<Contact/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
